import React, { useState, useCallback } from 'react';
import { Upload, MessageSquare, Send, Loader, CheckCircle, AlertCircle, ChevronDown, ChevronUp } from 'lucide-react';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';

const LeadMessenger = () => {
  const [leads, setLeads] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedLeads, setSelectedLeads] = useState(new Set());
  const [processingIndex, setProcessingIndex] = useState(null);
  const [messages, setMessages] = useState({});
  const [progress, setProgress] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [lastProcessedIndex, setLastProcessedIndex] = useState(0);

  const handleFileUpload = async (event) => {
    setLoading(true);
    setError(null);
    const file = event.target.files[0];
    
    try {
      let data;
      if (file.name.endsWith('.csv')) {
        // Handle CSV
        const text = await file.text();
        const result = Papa.parse(text, {
          header: true,
          skipEmptyLines: true,
          transformHeader: header => header.trim(),
        });
        
        if (result.errors.length > 0) {
          throw new Error('Error parsing CSV file');
        }
        
        data = result.data;
      } else {
        // Handle Excel
        const arrayBuffer = await file.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, {
          cellDates: true,
          cellNF: true,
          cellText: true
        });
        
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        data = XLSX.utils.sheet_to_json(worksheet);
      }
      
      // Validate required columns
      const requiredColumns = ['OWNER FULLNAME', 'BUSINESS NAME', 'CELLPHONE'];
      const missingColumns = requiredColumns.filter(col => 
        !data[0] || !Object.keys(data[0]).some(key => key.trim() === col)
      );
      
      if (missingColumns.length > 0) {
        throw new Error(`Missing required columns: ${missingColumns.join(', ')}`);
      }
      
      setLeads(data);
      setSelectedLeads(new Set(data.map((_, index) => index)));
      setLastProcessedIndex(0);
      setMessages({});
    } catch (error) {
      console.error('Error reading file:', error);
      setError(error.message);
      setLeads(null);
    } finally {
      setLoading(false);
    }
  };

  const allSelectedLeadsHaveMessages = () => {
    return Array.from(selectedLeads).every(index => messages[index]?.status === 'generated' || messages[index]?.status === 'sent');
  };

  const displayLeads = React.useMemo(() => leads || [], [leads]);


  const generateMessages = async () => {
    console.log("1. Function started", { leads: !!leads, loading, isGenerating });
    if (!leads || loading) return;
    
    console.log("2. Past initial checks", { leadsLength: leads.length });
    setLoading(true);
    const isCurrentlyGenerating = true;
    setIsGenerating(true);
    setError(null);
    window.onbeforeunload = () => "Message generation in progress. Are you sure you want to leave?";
    
    try {
      console.log("3. Entering try block");
      const newMessages = { ...messages };
      const startIdx = lastProcessedIndex || 0;
      console.log("4. Starting generation", { 
        startIdx, 
        totalLeads: leads.length,
        selectedLeadsSize: selectedLeads.size 
      });
      
      for (let i = startIdx; i < leads.length && isCurrentlyGenerating; i++) {
        console.log("5. Processing lead", { index: i, isSelected: selectedLeads.has(i) });
        if (!selectedLeads.has(i)) continue;
        
        setProcessingIndex(i);
        setProgress((i / leads.length) * 100);
        
        console.log("6. About to generate message for lead", { 
          name: leads[i]['OWNER FULLNAME'],
          business: leads[i]['BUSINESS NAME']
        });
        
        await new Promise(resolve => setTimeout(resolve, 200));
        
        newMessages[i] = {
          text: `Generated message for ${leads[i]['OWNER FULLNAME']} from ${leads[i]['BUSINESS NAME']}`,
          status: 'generated'
        };
        // Force a re-render by creating a new object
        setMessages({...newMessages});
        setLastProcessedIndex(i);
      }
    } catch (error) {
      console.error('Error generating messages:', error);
      setError('Failed to generate messages. Please try again.');
    } finally {
      setLoading(false);
      setProcessingIndex(null);
      setIsGenerating(false);
      window.onbeforeunload = null;
    }
  };

  const stopGeneration = () => {
    setIsGenerating(false);
  };

  const sendMessages = async () => {
    if (!leads || loading) return;
    
    setLoading(true);
    setError(null);
    
    try {
      const updatedMessages = { ...messages };
      for (let i = 0; i < leads.length; i++) {
        if (!selectedLeads.has(i) || !messages[i]) continue;
        
        setProcessingIndex(i);
        setProgress((i / leads.length) * 100);
        
        await new Promise(resolve => setTimeout(resolve, 100));
        
        updatedMessages[i] = {
          ...updatedMessages[i],
          status: 'sent'
        };
        setMessages({...updatedMessages});
      }
    } catch (error) {
      console.error('Error sending messages:', error);
      setError('Failed to send messages. Please try again.');
    } finally {
      setLoading(false);
      setProcessingIndex(null);
    }
  };

  const resetComponent = () => {
    setLeads(null);
    setMessages({});
    setSelectedLeads(new Set());
    setProcessingIndex(null);
    setProgress(0);
    setLastProcessedIndex(0);
    setError(null);
    setLoading(false);
    setIsGenerating(false);
  };

  return (
    <div className="w-full max-w-6xl mx-auto p-4 space-y-6">
      {/* Upload Section */}
      {!leads && (
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="p-6">
            <div className="flex items-center gap-3 mb-2">
              <Upload className="w-5 h-5 text-blue-500" />
              <h2 className="text-xl font-semibold text-gray-800">Upload Leads</h2>
            </div>
            
            <div className="grid grid-cols-3 gap-4 mb-6">
              <div className="p-4 rounded-lg bg-gray-50 border border-gray-200">
                <div className="flex items-center gap-2 mb-2">
                  <Upload className="w-4 h-4 text-blue-500" />
                  <h3 className="font-medium text-gray-700">Step 1: Upload</h3>
                </div>
                <p className="text-sm text-gray-600">Upload your Excel or CSV file with lead information</p>
              </div>
              
              <div className="p-4 rounded-lg bg-gray-50 border border-gray-200">
                <div className="flex items-center gap-2 mb-2">
                  <MessageSquare className="w-4 h-4 text-gray-400" />
                  <h3 className="font-medium text-gray-700">Step 2: Generate</h3>
                </div>
                <p className="text-sm text-gray-600">AI will create personalized messages for each lead</p>
              </div>
              
              <div className="p-4 rounded-lg bg-gray-50 border border-gray-200">
                <div className="flex items-center gap-2 mb-2">
                  <Send className="w-4 h-4 text-gray-400" />
                  <h3 className="font-medium text-gray-700">Step 3: Send</h3>
                </div>
                <p className="text-sm text-gray-600">Review and send messages to your leads</p>
              </div>
            </div>
            
            <label className="
              flex flex-col items-center justify-center w-full h-48 
              border-2 border-dashed rounded-lg cursor-pointer
              transition-colors duration-200
              border-blue-400 bg-blue-50 hover:bg-blue-100
            ">
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <Upload className="w-10 h-10 mb-4 text-blue-500" />
                <p className="mb-2 text-sm text-gray-700">
                  <span className="font-semibold">Click to upload</span> or drag and drop
                </p>
                <p className="text-xs text-gray-500">Excel (.xlsx, .xls) or CSV files</p>
              </div>
              <input 
                type="file" 
                className="hidden" 
                accept=".xlsx,.xls,.csv"
                onChange={handleFileUpload}
                disabled={loading}
              />
            </label>

            {error && (
              <div className="mt-4 p-4 bg-red-50 rounded-lg border border-red-200">
                <div className="flex items-center gap-2 text-red-600">
                  <AlertCircle className="w-4 h-4" />
                  <p className="text-sm">{error}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Data Table */}
      {leads && (
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-3">
                <MessageSquare className="w-5 h-5 text-blue-500" />
                <h2 className="text-xl font-semibold text-gray-800">
                  Lead Messages
                </h2>
              </div>
              
              <div className="flex gap-3">
                <button
                  onClick={resetComponent}
                  className="px-4 py-2 rounded-lg font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-100"
                >
                  Start Over
                </button>
                <button
                    onClick={generateMessages}
                    disabled={loading || allSelectedLeadsHaveMessages()}
                    className={`
                        px-4 py-2 rounded-lg font-medium
                        transition-all duration-200
                        ${loading || allSelectedLeadsHaveMessages()
                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                        : 'bg-blue-500 hover:bg-blue-600 text-white'}
                    `}
                    >
                    {loading 
                        ? lastProcessedIndex > 0 
                        ? 'Resuming...' 
                        : 'Generating...'
                        : allSelectedLeadsHaveMessages()
                        ? 'All Messages Generated'
                        : lastProcessedIndex > 0
                            ? 'Resume Generation'
                            : 'Generate Messages'
                    }
                </button>
                {loading && (
                  <button
                    onClick={stopGeneration}
                    className="px-4 py-2 rounded-lg font-medium bg-red-500 hover:bg-red-600 text-white"
                  >
                    Stop Generation
                  </button>
                )}
                <button
                  onClick={sendMessages}
                  disabled={loading || Object.keys(messages).length === 0}
                  className={`
                    px-4 py-2 rounded-lg font-medium
                    transition-all duration-200
                    ${loading || Object.keys(messages).length === 0
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-green-500 hover:bg-green-600 text-white'}
                  `}
                >
                  {loading ? 'Sending...' : 'Send Messages'}
                </button>
              </div>
            </div>

            {error && (
              <div className="mb-4 p-4 bg-red-50 rounded-lg border border-red-200">
                <div className="flex items-center gap-2 text-red-600">
                  <AlertCircle className="w-4 h-4" />
                  <p className="text-sm">{error}</p>
                </div>
              </div>
            )}

            {/* Table Container */}
            <div className="border border-gray-200 rounded-lg overflow-hidden">
              <div className="overflow-x-auto">
                <div className="overflow-y-auto max-h-[600px]">
                  <table className="w-full">
                    <thead className="bg-gray-50 sticky top-0">
                      <tr>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                          <label className="flex items-center">
                            <input
                              type="checkbox"
                              className="rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                              checked={selectedLeads.size === leads.length}
                              onChange={(e) => {
                                setSelectedLeads(e.target.checked 
                                  ? new Set(leads.map((_, i) => i))
                                  : new Set()
                                );
                              }}
                            />
                          </label>
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                          Owner Fullname
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                          Business Name
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                          Cellphone
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                          Message Preview
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {displayLeads.map((lead, index) => (
                        <tr 
                            key={index}
                            className={`
                            hover:bg-gray-50 transition-colors duration-150
                            ${processingIndex === index ? 'bg-blue-50' : ''}
                            `}
                        >
                            <td className="px-4 py-3 whitespace-nowrap">
                            <input
                                type="checkbox"
                                className="rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                                checked={selectedLeads.has(index)}
                                onChange={(e) => {
                                const newSelected = new Set(selectedLeads);
                                if (e.target.checked) {
                                    newSelected.add(index);
                                } else {
                                    newSelected.delete(index);
                                }
                                setSelectedLeads(newSelected);
                                }}
                            />
                            </td>
                            <td className="px-4 py-3 whitespace-nowrap">
                            <div className="text-sm text-gray-900">{lead['OWNER FULLNAME']}</div>
                            </td>
                            <td className="px-4 py-3 whitespace-nowrap">
                            <div className="text-sm text-gray-900">{lead['BUSINESS NAME']}</div>
                            </td>
                            <td className="px-4 py-3 whitespace-nowrap">
                            <div className="text-sm text-gray-900">{lead['CELLPHONE']}</div>
                            </td>
                            <td className="px-4 py-3">
                            <div className="text-sm text-gray-900 max-w-md overflow-hidden text-ellipsis">
                                {messages[index]?.text || '-'}
                            </div>
                            </td>
                            <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex items-center gap-2">
                                {processingIndex === index && loading ? (
                                <Loader className="w-4 h-4 text-blue-500 animate-spin" />
                                ) : messages[index]?.status === 'sent' ? (
                                <CheckCircle className="w-4 h-4 text-green-500" />
                                ) : messages[index]?.status === 'generated' ? (
                                <MessageSquare className="w-4 h-4 text-blue-500" />
                                ) : (
                                <div className="w-4 h-4" />
                                )}
                                <span className="text-sm text-gray-500">
                                {messages[index]?.status 
                                    ? messages[index].status.charAt(0).toUpperCase() + 
                                    messages[index].status.slice(1)
                                    : 'Pending'
                                }
                                </span>
                            </div>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Progress Bar */}
            {loading && (
              <div className="mt-4 space-y-2">
                <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                  <div 
                    className="h-full bg-blue-500 transition-all duration-300"
                    style={{ width: `${progress}%` }}
                  />
                </div>
                <p className="text-sm text-gray-600 flex items-center gap-2">
                  <Loader className="w-4 h-4 animate-spin" />
                  {processingIndex !== null 
                    ? `Processing lead ${processingIndex + 1} of ${leads.length}`
                    : 'Processing...'}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LeadMessenger;