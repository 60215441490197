import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Search,
  MoreVertical,
  Edit,
  Send,
  Loader,
  AlertCircle,
  X,
  UserPlus,
} from 'lucide-react';
import { useConversations, useMessages, useSendMessage } from '../hooks/useApi';
import { useQueryClient } from '@tanstack/react-query';
import Pusher from 'pusher-js';

const AdvancedMessageInterface = () => {
  console.log('Messages component rendering');
  
  const queryClient = useQueryClient();

  // Initialize activeChat from React Query cache
  const [activeChat, setActiveChat] = useState(() => {
    const cache = queryClient.getQueryData(['activeChat']);
    console.log('Initializing activeChat from cache:', cache);
    return cache || null;
  });
  
  const [newMessage, setNewMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentTab, setCurrentTab] = useState('All');
  const [isNewConversation, setIsNewConversation] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [newContactName, setNewContactName] = useState('');
  const [sendError, setSendError] = useState(null);
  const [isMarkingRead, setIsMarkingRead] = useState(false);
  
  const messagesEndRef = useRef(null);
  const messageInputRef = useRef(null);
  const phoneNumberRef = useRef(null);

  // Persist activeChat in React Query cache whenever it changes
  useEffect(() => {
    console.log('Persisting activeChat to cache:', activeChat);
    queryClient.setQueryData(['activeChat'], activeChat);
  }, [activeChat, queryClient]);

  // Use React Query hooks
  const { 
    data: conversationsData, 
    isLoading: conversationsLoading,
    error: conversationsError 
  } = useConversations();
  
  const { 
    data: messagesData,
    isLoading: messagesLoading,
    error: messagesError 
  } = useMessages(activeChat);
  
  const sendMessageMutation = useSendMessage();

  // Memoize derived data
  const conversations = useMemo(() => 
    conversationsData?.conversations || [], 
    [conversationsData]
  );

  const messages = useMemo(() => 
    messagesData?.messages || [], 
    [messagesData]
  );

  // Loading state derived from React Query
  const isLoading = conversationsLoading || messagesLoading;

  // Handle sending messages (simplified)
  const handleSend = useMemo(() => async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    const phoneNumberToUse = isNewConversation ? newPhoneNumber : activeChat;
    const tempId = 'temp-' + Date.now();
    const contentToSend = newMessage.trim();

    // Immediately clear the input and reset error
    setNewMessage('');
    setSendError(null);

    // Optimistically update
    queryClient.setQueryData(['messages', phoneNumberToUse], (oldData) => {
      const newMessageObj = {
        id: tempId,
        content: contentToSend,
        direction: 'outbound',
        status: 'sending',
        created_at: new Date().toISOString(),
        phone_number: phoneNumberToUse
      };

      if (!oldData) {
        return { messages: [newMessageObj] };
      }
      return {
        ...oldData,
        messages: [...oldData.messages, newMessageObj],
      };
    });

    try {
      // Call our send message endpoint
      await sendMessageMutation.mutateAsync({
        phoneNumber: phoneNumberToUse,
        content: contentToSend,
        contactName: isNewConversation ? newContactName : undefined,
      });

      if (isNewConversation) {
        setActiveChat(newPhoneNumber);
        setIsNewConversation(false);
      }
    } catch (error) {
      // Remove bubble on failure
      queryClient.setQueryData(['messages', phoneNumberToUse], (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          messages: oldData.messages.filter((m) => m.id !== tempId),
        };
      });
      setSendError('Failed to send message');
    }
  }, [
    activeChat, 
    newMessage, 
    isNewConversation, 
    newPhoneNumber, 
    newContactName, 
    sendMessageMutation,
    queryClient
  ]);

  // Debug logging
  useEffect(() => {
    console.log('Conversations data updated:', conversationsData);
  }, [conversationsData]);

  const scrollToBottom = () => {
    requestAnimationFrame(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ 
          behavior: "auto",
          block: "end"
        });
      }
    });
  };

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  const API_BASE = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:3000/api' 
    : `https://${window.location.hostname}/api`;

  // Handle marking conversation as read
  const markConversationAsRead = async (phoneNumber) => {
    // Ensure the database sees the correct format
    phoneNumber = formatPhoneNumberForStorage(phoneNumber);

    // Find the conversation in cache
    const cachedData = queryClient.getQueryData(['conversations']);
    const conversation = cachedData?.conversations?.find(
      conv => conv.phone_number === phoneNumber
    );

    // Only mark as read if there are unread messages
    if (!conversation || conversation.unread_count === 0) {
      console.log('Skipping mark as read - no unread messages');
      return;
    }

    try {
      setIsMarkingRead(true);
      const response = await fetch(`${API_BASE}/mark-read/${phoneNumber}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to mark as read');
      }
      
      await response.json(); // Wait for the response

      // Update the cache after successful API call
      queryClient.setQueryData(['conversations'], old => ({
        ...old,
        conversations: old.conversations.map(conv => 
          conv.phone_number === phoneNumber 
            ? { ...conv, unread_count: 0 }
            : conv
        )
      }));
    } catch (error) {
      console.error('Failed to mark conversation as read:', error);
      // Revert the optimistic update on failure
      queryClient.setQueryData(['conversations'], old => ({
        ...old,
        conversations: old.conversations.map(conv => 
          conv.phone_number === phoneNumber 
            ? { ...conv, unread_count: conversation.unread_count }
            : conv
        )
      }));
    } finally {
      setIsMarkingRead(false);
    }
  };

  const formatPhoneNumberForUI = (phone) => {
    const cleaned = phone.replace(/\D/g, '');
    if (cleaned.length === 10) {
      return `${cleaned.slice(0,3)}-${cleaned.slice(3,6)}-${cleaned.slice(6)}`;
    }
    return cleaned;
  };
  
  const formatPhoneNumberForStorage = (phone) => {
    const cleaned = phone.replace(/\D/g, '');
    return cleaned;
  };

  const validatePhoneNumber = (phone) => {
    // Basic phone number validation - can be enhanced based on your needs
    const cleaned = phone.replace(/\D/g, '');
    return cleaned.length >= 10 && cleaned.length <= 15;
  };

  const handleNewConversationClick = () => {
    setIsNewConversation(true);
    setNewPhoneNumber('');
    setNewContactName('');
    setTimeout(() => phoneNumberRef.current?.focus(), 0);
  };

  useEffect(() => {
    if (activeChat) {
      messageInputRef.current?.focus();
    }
  }, [activeChat]);

  const formatMessageTime = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const isToday = date.toDateString() === now.toDateString();

    if (isToday) {
      return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
    }
    
    return date.toLocaleDateString([], { 
      month: 'short', 
      day: 'numeric'
    });
  };

  const filterConversations = (conversations, searchTerm) => {
    if (!searchTerm.trim()) {
      return conversations.filter((conv) => {
        if (currentTab === 'Unread') {
          return conv.unread_count > 0;
        }
        return true;
      });
    }

    const searchRegex = new RegExp(searchTerm, 'i');
    return conversations.filter((conv) => {
      const matchesTab = currentTab === 'All' || 
        (currentTab === 'Unread' && conv.unread_count > 0);
      
      const matchesName = searchRegex.test(`${conv.first_name} ${conv.last_name}`);
      const matchesMessage = searchRegex.test(conv.last_message_preview);
      const matchesPhone = searchRegex.test(conv.phone_number);

      return matchesTab && (matchesName || matchesMessage || matchesPhone);
    });
  };

  const highlightMatch = (text, searchTerm) => {
    if (!searchTerm.trim()) return text;

    try {
      const regex = new RegExp(`(${searchTerm})`, 'gi');
      return text.split(regex).map((part, i) => 
        regex.test(part) ? (
          <span key={i} className="bg-yellow-200 dark:bg-yellow-900">{part}</span>
        ) : part
      );
    } catch (e) {
      return text;
    }
  };

  const filteredConversations = filterConversations(conversations, searchTerm)
    .sort((a, b) => new Date(b.last_message_timestamp) - new Date(a.last_message_timestamp));

  // Handle conversation selection
  const handleConversationSelect = async (phoneNumber) => {
    console.log('Selecting conversation:', phoneNumber);
    // Always mark as read and set active chat
    await markConversationAsRead(phoneNumber);
    setActiveChat(phoneNumber);
  };

  // Debug logging for component state
  useEffect(() => {
    console.log('Current activeChat:', activeChat);
    console.log('Cache status:', queryClient.getQueryData(['activeChat']));
  }, [activeChat, queryClient]);

  // Cleanup function to remove cache when component unmounts
  useEffect(() => {
    return () => {
      // Don't remove the cache on unmount so it persists between tab switches
      // queryClient.removeQueries(['activeChat']);
    };
  }, [queryClient]);

  // Move Pusher setup to its own effect
  useEffect(() => {
    if (!process.env.REACT_APP_PUSHER_KEY || !process.env.REACT_APP_PUSHER_CLUSTER) {
      console.error('Pusher configuration missing');
      return;
    }

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });

    const channel = pusher.subscribe('messages');
    
    // Handle incoming messages
    channel.bind('new-message', (data) => {
      // Update messages for the relevant conversation
      queryClient.setQueryData(['messages', data.phoneNumber], (oldData) => {
        if (!oldData) {
          return { messages: [data.message] };
        }
        return {
          ...oldData,
          messages: [...oldData.messages, data.message]
        };
      });

      // Update conversations list
      queryClient.setQueryData(['conversations'], (oldData) => {
        if (!oldData?.conversations) return oldData;

        const existingConversation = oldData.conversations.find(
          conv => conv.phone_number === data.phoneNumber
        );

        if (!existingConversation && data.isNewConversation) {
          // Add new conversation to the list
          return {
            ...oldData,
            conversations: [{
              phone_number: data.phoneNumber,
              last_message_preview: data.message.content,
              last_message_timestamp: data.message.created_at,
              unread_count: activeChat !== data.phoneNumber ? 1 : 0,
              first_name: '',
              last_name: ''
            }, ...oldData.conversations]
          };
        }

        // Update existing conversation
        const updatedConversations = oldData.conversations.map(conv => 
          conv.phone_number === data.phoneNumber 
            ? {
                ...conv,
                last_message_preview: data.message.content,
                last_message_timestamp: data.message.created_at,
                unread_count: activeChat !== data.phoneNumber 
                  ? (conv.unread_count || 0) + 1 
                  : conv.unread_count
              }
            : conv
        );

        return {
          ...oldData,
          conversations: updatedConversations,
        };
      });

      // Scroll to bottom if viewing the relevant conversation
      if (data.phoneNumber === activeChat) {
        scrollToBottom();
      }
    });

    return () => {
      channel.unbind('new-message');
      pusher.unsubscribe('messages');
      pusher.disconnect();
    };
  }, [activeChat, queryClient, scrollToBottom]);

  // Use loading states in your JSX
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
      </div>
    );
  }

  if (conversationsError || messagesError) {
    return (
      <div className="flex items-center justify-center h-screen text-red-500">
        Error loading data: {conversationsError?.message || messagesError?.message}
      </div>
    );
  }

  return (
    <div className="w-full h-[700px] max-w-5xl">
      <div className="flex h-full rounded-xl bg-white dark:bg-gray-800 shadow-xl dark:shadow-xl overflow-hidden">
        {/* Sidebar */}
        <div className="w-80 border-r border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900 flex flex-col overflow-hidden">
          {/* Search Header */}
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Messages</h2>
              <button 
                onClick={() => setIsNewConversation(!isNewConversation)}
                className={`p-2 rounded-full transition-all duration-200 ${
                  isNewConversation 
                    ? 'bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-400 hover:bg-blue-200 dark:hover:bg-blue-800'
                    : 'hover:bg-gray-200 dark:hover:bg-gray-700 text-blue-500'
                }`}
              >
                <Edit size={20} className={`transform transition-transform duration-200 ${
                  isNewConversation ? 'rotate-45' : ''
                }`} />
              </button>
            </div>
            {!isNewConversation && (
              <div className="relative">
                <Search size={16} className="absolute left-3 top-3 text-gray-400" />
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search messages or contacts"
                  className="w-full rounded-full bg-gray-200 dark:bg-gray-700 pl-10 pr-4 py-2 
                    text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400
                    focus:outline-none focus:ring-1 focus:ring-gray-300 dark:focus:ring-gray-600"
                />
                {searchTerm && (
                  <button
                    onClick={() => setSearchTerm('')}
                    className="absolute right-3 top-2 p-1 hover:bg-gray-300 dark:hover:bg-gray-600 
                      rounded-full text-gray-500 dark:text-gray-400"
                  >
                    <X size={14} />
                  </button>
                )}
              </div>
            )}
          </div>
  
          {/* Tabs */}
          <div className="flex border-b border-gray-200 dark:border-gray-700">
            <button
              onClick={() => setCurrentTab('All')}
              className={`flex-1 p-2 text-center transition-colors ${
                currentTab === 'All'
                  ? 'border-b-2 border-blue-500 text-blue-500'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800'
              }`}
            >
              All
            </button>
            <button
              onClick={() => setCurrentTab('Unread')}
              className={`flex-1 p-2 text-center transition-colors ${
                currentTab === 'Unread'
                  ? 'border-b-2 border-blue-500 text-blue-500'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800'
              }`}
            >
              Unread
            </button>
          </div>
  
          {/* Conversations List */}
          <div className="overflow-y-auto overflow-x-hidden flex-1">
            {filteredConversations.map((conv) => (
              <div
                key={conv.phone_number}
                onClick={() => {
                  handleConversationSelect(conv.phone_number);
                }}
                className={`flex items-center p-4 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800
                  ${activeChat === conv.phone_number ? 'bg-gray-100 dark:bg-gray-800' : ''}
                  ${isMarkingRead && conv.phone_number === activeChat ? 'opacity-75' : ''}`}
              >
                <div className="relative">
                  <div className="w-12 h-12 rounded-full bg-gray-300 dark:bg-gray-600 flex items-center justify-center">
                    <span className="text-lg font-semibold text-gray-600 dark:text-gray-200">
                      {conv.first_name ? conv.first_name[0] : '#'}
                    </span>
                  </div>
                </div>
                <div className="ml-4 flex-1 min-w-0">
                  <div className="flex justify-between items-center">
                    <h3 className="font-semibold truncate pr-2 text-gray-900 dark:text-white">
                      {searchTerm ? 
                        highlightMatch(`${conv.first_name || ''} ${conv.last_name || ''}`.trim() || conv.phone_number, searchTerm) :
                        `${conv.first_name || ''} ${conv.last_name || ''}`.trim() || formatPhoneNumberForUI(conv.phone_number)
                      }
                    </h3>
                    <span className="text-xs text-gray-500 dark:text-gray-400 flex-shrink-0">
                      {formatMessageTime(conv.last_message_timestamp)}
                    </span>
                  </div>
                  <div className="flex justify-between items-start">
                    <p className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2 max-w-[180px]">
                      {searchTerm ? 
                        highlightMatch(conv.last_message_preview, searchTerm) :
                        conv.last_message_preview
                      }
                    </p>
                    {conv.unread_count > 0 && (
                      <span className="ml-2 bg-blue-500 text-white rounded-full px-2 py-0.5 text-xs flex-shrink-0">
                        {conv.unread_count}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
  
        {/* Chat Area */}
        <div className="flex-1 flex flex-col bg-gray-50 dark:bg-gray-900">
          {isNewConversation ? (
            <>
              {/* New Conversation Header */}
              <div className="p-4 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
                <div className="flex flex-col space-y-4">
                  <div className="flex items-center justify-between">
                    <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                      New Conversation
                    </h2>
                    <button 
                      onClick={() => setIsNewConversation(false)}
                      className="text-sm text-blue-500 hover:text-blue-600 dark:hover:text-blue-400 font-medium"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="space-y-3">
                    <div className="relative">
                      <input
                        ref={phoneNumberRef}
                        type="tel"
                        value={newPhoneNumber}
                        onChange={(e) => setNewPhoneNumber(e.target.value)}
                        placeholder="Enter phone number"
                        className="w-full px-4 py-2.5 rounded-lg
                          bg-gray-50 dark:bg-gray-700
                          border border-gray-300 dark:border-gray-600
                          text-gray-900 dark:text-white
                          placeholder-gray-500 dark:placeholder-gray-400
                          transition-colors duration-200
                          focus:outline-none focus:ring-2 focus:ring-blue-500/20 
                          focus:border-blue-500 dark:focus:border-blue-400"
                      />
                    </div>
                    <div className="relative">
                      <input
                        type="text"
                        value={newContactName}
                        onChange={(e) => setNewContactName(e.target.value)}
                        placeholder="Contact name (optional)"
                        className="w-full px-4 py-2.5 rounded-lg
                          bg-gray-50 dark:bg-gray-700
                          border border-gray-300 dark:border-gray-600
                          text-gray-900 dark:text-white
                          placeholder-gray-500 dark:placeholder-gray-400
                          transition-colors duration-200
                          focus:outline-none focus:ring-2 focus:ring-blue-500/20 
                          focus:border-blue-500 dark:focus:border-blue-400"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Empty State */}
              <div className="flex-1 flex items-center justify-center text-gray-500 dark:text-gray-400">
                Enter details above to start a new conversation
              </div>

              {/* Message Input */}
              <form onSubmit={handleSend} className="p-4 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
                {sendError && (
                  <div className="flex items-center text-red-500 text-sm mb-2">
                    <AlertCircle size={16} className="mr-1" />
                    {sendError}
                  </div>
                )}
                <div className="flex items-center space-x-2">
                  <input
                    ref={messageInputRef}
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type your message"
                    className="flex-1 rounded-full border border-gray-300 dark:border-gray-600 
                      px-4 py-2 bg-white dark:bg-gray-700
                      text-gray-900 dark:text-white
                      placeholder-gray-500 dark:placeholder-gray-400
                      focus:outline-none focus:border-gray-400 dark:focus:border-gray-500 
                      focus:ring-1 focus:ring-gray-200 dark:focus:ring-gray-600"
                  />
                  <button
                    type="submit"
                    disabled={!newMessage.trim() || !newPhoneNumber.trim()}
                    className="rounded-full bg-blue-500 p-2 text-white 
                      hover:bg-blue-600 dark:hover:bg-blue-400
                      focus:outline-none focus:ring-2 
                      focus:ring-blue-500/20 dark:focus:ring-blue-500/40 
                      disabled:opacity-50 disabled:cursor-not-allowed 
                      transition-colors"
                  >
                    <Send size={20} />
                  </button>
                </div>
              </form>
            </>
          ) : activeChat ? (
            <>
              {/* Regular Chat Header */}
              <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
                <div className="flex items-center">
                  <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                    {conversations.find(c => c.phone_number === activeChat)?.first_name} {conversations.find(c => c.phone_number === activeChat)?.last_name}
                  </h2>
                  <span className="text-sm text-gray-500 dark:text-gray-400 ml-2">
                    {formatPhoneNumberForUI(activeChat)}
                  </span>
                </div>
                <div className="flex items-center space-x-4">
                  <button className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full">
                    <MoreVertical size={20} className="text-gray-500 dark:text-gray-400" />
                  </button>
                </div>
              </div>

              {/* Messages */}
              <div className="flex-1 overflow-y-auto p-4 space-y-4">
                {isLoading ? (
                  <div className="flex-1 flex items-center justify-center">
                    <Loader className="w-8 h-8 text-blue-500 animate-spin" />
                  </div>
                ) : (
                  <>
                    {messages.map((message) => (
                      <div
                        key={message.id}
                        className={`flex ${
                          message.direction === 'outbound'
                            ? 'justify-end'
                            : 'justify-start'
                        }`}
                      >
                        <div
                          className={`max-w-[70%] rounded-2xl px-4 py-2 ${
                            message.direction === 'outbound'
                              ? 'bg-blue-500 text-white'
                              : 'bg-white dark:bg-gray-800 text-gray-900 dark:text-white'
                          }`}
                        >
                          <p className="break-words">{message.content}</p>
                          <p
                            className={`text-xs mt-1 ${
                              message.direction === 'outbound'
                                ? 'text-blue-100'
                                : 'text-gray-500 dark:text-gray-400'
                            }`}
                          >
                            {formatMessageTime(message.created_at)}
                          </p>
                        </div>
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                  </>
                )}
              </div>

              {/* Message Input */}
              <form onSubmit={handleSend} className="p-4 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
                {sendError && (
                  <div className="flex items-center text-red-500 text-sm mb-2">
                    <AlertCircle size={16} className="mr-1" />
                    {sendError}
                  </div>
                )}
                <div className="flex items-center space-x-2">
                  <input
                    ref={messageInputRef}
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type your message"
                    className="flex-1 rounded-full border border-gray-300 dark:border-gray-600 
                      px-4 py-2 bg-white dark:bg-gray-700
                      text-gray-900 dark:text-white
                      placeholder-gray-500 dark:placeholder-gray-400
                      focus:outline-none focus:border-gray-400 dark:focus:border-gray-500 
                      focus:ring-1 focus:ring-gray-200 dark:focus:ring-gray-600"
                  />
                  <button
                    type="submit"
                    disabled={!newMessage.trim()}
                    className="rounded-full bg-blue-500 p-2 text-white 
                      hover:bg-blue-600 dark:hover:bg-blue-400
                      focus:outline-none focus:ring-2 
                      focus:ring-blue-500/20 dark:focus:ring-blue-500/40 
                      disabled:opacity-50 disabled:cursor-not-allowed 
                      transition-colors"
                  >
                    <Send size={20} />
                  </button>
                </div>
              </form>
            </>
          ) : (
            <div className="flex-1 flex items-center justify-center text-gray-500 dark:text-gray-400">
              Select a conversation to start messaging
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Use React.memo with deps check
export default React.memo(AdvancedMessageInterface, (prev, next) => {
  return true;
});