import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';

// Fetch conversations
export function useConversations() {
  console.log('useConversations hook called');

  return useQuery({
    queryKey: ['conversations'],
    queryFn: async () => {
      console.log('Conversations API call started');
      const response = await fetch('/api/conversations');
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      console.log('Conversations API call completed');
      return data;
    },
    staleTime: Infinity, // Never consider data stale
    cacheTime: Infinity, // Keep in cache indefinitely
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false, // Don't retry failed requests
    keepPreviousData: true, // Keep showing old data while fetching new data
  });
}

// Fetch messages for a specific conversation
export function useMessages(phoneNumber) {
  const queryClient = useQueryClient();
  
  // Log cache status
  const cachedData = queryClient.getQueryData(['messages', phoneNumber]);
  console.log('Cache status for', phoneNumber, ':', cachedData ? 'HIT' : 'MISS');

  return useQuery({
    queryKey: ['messages', phoneNumber],
    queryFn: async () => {
      if (!phoneNumber) return null;
      console.log('🔴 Messages API call started for:', phoneNumber);
      const response = await fetch(`/api/messages/${phoneNumber}`);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      console.log('✅ Messages API call completed for:', phoneNumber);
      return data;
    },
    enabled: !!phoneNumber,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    keepPreviousData: true,
    suspense: false,
    placeholderData: (previousData) => previousData,
  });
}

// Fetch forms (already working well, but let's be explicit with settings)
export function useForms() {
  return useQuery({
    queryKey: ['forms'],
    queryFn: async () => {
      const response = await fetch('/api/forms/pdfs');
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    },
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep in cache for 30 minutes
    refetchOnMount: false,
    refetchOnWindowFocus: false, // Forms are less likely to change
  });
}

// Send message mutation with optimistic updates
export function useSendMessage() {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({ phoneNumber, content, contactName }) => {
      const response = await fetch('/api/messages/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          phone_number: phoneNumber,
          content,
          contact_name: contactName,
        }),
      });
      if (!response.ok) throw new Error('Failed to send message');
      return response.json();
    },
    // Optimistic update for better UX
    onMutate: async ({ phoneNumber, content }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries(['messages', phoneNumber]);
      await queryClient.cancelQueries(['conversations']);

      // Snapshot the previous value
      const previousMessages = queryClient.getQueryData(['messages', phoneNumber]);
      const previousConversations = queryClient.getQueryData(['conversations']);

      // Optimistically update messages
      if (previousMessages) {
        queryClient.setQueryData(['messages', phoneNumber], old => ({
          ...old,
          messages: [...old.messages, {
            id: 'temp-' + Date.now(),
            content,
            direction: 'outbound',
            status: 'pending',
            created_at: new Date().toISOString()
          }]
        }));
      }

      // Return context with the snapshotted value
      return { previousMessages, previousConversations };
    },
    onError: (err, variables, context) => {
      // Rollback on error
      if (context?.previousMessages) {
        queryClient.setQueryData(['messages', variables.phoneNumber], context.previousMessages);
      }
      if (context?.previousConversations) {
        queryClient.setQueryData(['conversations'], context.previousConversations);
      }
    },
    onSettled: (data, error, variables) => {
      // Always refetch after error or success to sync with server
      queryClient.invalidateQueries(['messages', variables.phoneNumber]);
      queryClient.invalidateQueries(['conversations']);
    },
  });
} 