import React, { useState, useEffect } from 'react';
import { 
  MessageSquare, 
  FileText, 
  LogOut,
  Moon,
  Sun,
  ChevronLeft,
  ChevronDown,
  Users
} from 'lucide-react';
import { NavLink } from 'react-router-dom';

const Sidebar = ({ onLogout }) => {
  const [isDark, setIsDark] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedUser, setSelectedUser] = useState('Kyle');
  
  useEffect(() => {
    const darkMode = localStorage.getItem('isDark') === 'true';
    setIsDark(darkMode);
  }, []);

  useEffect(() => {
    localStorage.setItem('isDark', isDark);
    if (isDark) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDark]);

  const menuItems = [
    { icon: MessageSquare, label: 'Messages', link: '/messages' },
    { icon: FileText, label: 'Forms', link: '/forms' },
    { icon: Users, label: 'Bulk Messaging', link: '/bulk-messaging' },
  ];

  return (
    <div className={`
      fixed top-0 left-0 h-screen
      transition-all duration-300 ease-in-out
      ${isCollapsed ? 'w-20' : 'w-72'}
      ${isDark ? 'bg-gray-900' : 'bg-white'}
      border-r ${isDark ? 'border-gray-700' : 'border-gray-200'}
      flex flex-col
      shadow-xl
    `}>
      {/* Logo Section */}
      <div className="p-6 flex items-center gap-4">
        <div className={`
          w-10 h-10 rounded-lg flex items-center justify-center
          flex-shrink-0 overflow-hidden
        `}>
          <img src="/logo.png" alt="Logo" className="w-full h-full object-cover" />
        </div>
        <span className={`
          font-semibold text-lg
          ${isDark ? 'text-white' : 'text-gray-800'}
          ${isCollapsed ? 'hidden' : 'block'}
          transition-all duration-300
        `}>
          FIRSTLINE
        </span>
      </div>

      {/* User Dropdown */}
      <div className={`px-6 mb-6 ${isCollapsed ? 'hidden' : 'block'}`}>
        <div className={`
          relative rounded-lg
          ${isDark ? 'bg-gray-800' : 'bg-gray-100'}
        `}>
          <select
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            className={`
              w-full py-2.5 pl-4 pr-10 rounded-lg
              bg-transparent 
              appearance-none [-webkit-appearance:none] [-moz-appearance:none]
              ${isDark ? 'text-white' : 'text-gray-800'}
              focus:outline-none
            `}
          >
            <option value="Kyle">Kyle</option>
          </select>
          <ChevronDown className={`
            absolute right-3 top-1/2 transform -translate-y-1/2
            w-4 h-4
            ${isDark ? 'text-gray-400' : 'text-gray-500'}
          `} />
        </div>
      </div>

      {/* Navigation Menu */}
      <nav className="flex-1 px-4">
        {menuItems.map((item, index) => (
          <NavLink
            key={index}
            to={item.link}
            className={({ isActive }) => `
              flex items-center gap-4 px-4 py-3 rounded-lg mb-2
              transition-all duration-200
              ${isActive ? 
                (isDark ? 'bg-indigo-600 text-white' : 'bg-indigo-100 text-indigo-600') : 
                (isDark ? 'text-gray-300 hover:bg-gray-800' : 'text-gray-600 hover:bg-gray-100')
              }
            `}
          >
            <item.icon className={`w-6 h-6 ${isCollapsed ? 'mx-auto' : ''}`} />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>{item.label}</span>
          </NavLink>
        ))}
      </nav>

      {/* Bottom Section */}
      <div className="p-4 mt-auto">
        {/* Logout Button */}
        <button
          onClick={onLogout}
          className={`
            flex items-center gap-4 px-4 py-3 rounded-lg w-full mb-2
            ${isDark ? 'text-gray-300 hover:bg-gray-800' : 'text-gray-600 hover:bg-gray-100'}
            transition-all duration-200
          `}
          aria-label="Logout"
        >
          <LogOut className={`w-6 h-6 ${isCollapsed ? 'mx-auto' : ''}`} />
          <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Logout</span>
        </button>

        {/* Dark Mode Toggle */}
        <div className={`
          flex items-center gap-4 px-4 py-3 rounded-lg
          ${isDark ? 'bg-gray-800' : 'bg-gray-100'}
        `}>
          <button 
            onClick={() => setIsDark(!isDark)}
            className="flex items-center gap-4 w-full"
            aria-label="Toggle Dark Mode"
          >
            {isDark ? (
              <Moon className="w-6 h-6 text-gray-300" />
            ) : (
              <Sun className="w-6 h-6 text-gray-600" />
            )}
            <span className={`
              ${isCollapsed ? 'hidden' : 'block'}
              ${isDark ? 'text-gray-300' : 'text-gray-600'}
            `}>
              {isDark ? 'Dark Mode' : 'Light Mode'}
            </span>
          </button>
        </div>
      </div>

      {/* Collapse Toggle */}
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className={`
          absolute -right-3 top-8
          w-6 h-6 rounded-full
          ${isDark ? 'bg-gray-800 text-white' : 'bg-white text-gray-600'}
          shadow-md
          flex items-center justify-center
          transition-transform duration-300
          ${isCollapsed ? 'rotate-180' : ''}
        `}
        aria-label="Toggle Sidebar"
      >
        <ChevronLeft className="w-4 h-4" />
      </button>
    </div>
  );
};

export default Sidebar;